.user-item {
    display: table-row;
    margin: 4px 8px;
    padding: 8px 16px;
    background: #ffffff10;
    border-radius: 8px;

    .user-image {

        img {
            width: 60px;
            height: 60px;
            min-width: 60px;
            min-height:60px;
        }
    } 

    .user-id {
        font-style: italic;
        margin-bottom: 2px;
    }

    .user-data {
        display: table-cell;
        vertical-align: top;
        padding: 16px;
        width: 280px;

        .wrapper-data {
            display: flex;
        }

        .wrapper-name {
            text-align: start;
            margin: 8px;
        }
    }

    .image-approval {
        display: table-cell;
        vertical-align: middle;

        .temp-image {
            img {
                width: 200px;
                height: 200px;
                min-width: 200px;
                min-height: 200px;
                border-radius: 16px;
                border: solid 4px #ffffff;
            }
        }
    }


    .user-actions {
        display: table-cell;
        vertical-align: top;
        padding: 16px;
        width: 0;

        button {
            background-color: #950f0f;
        }

        button:hover {
            background-color: #aa0000;
        }

        .approve {
            background-color: #73ff5060;
        }

        .approve:hover {
            background-color: #94ff62a4;
        }

    }

    .user-relationships {
        display: table-cell;
        vertical-align: top;
        padding: 16px 32px;
        width: 400px;
        
        .relationship-list {
            background-color: #ffffff10;
            border-radius: 4px;
            height: 160px;
            max-height: 160px;
            padding: 8px;
            overflow: auto;
            margin-top: 4px;
        }

        ul {
            margin-top: 4px;
        }

        li {
            display: flex;
            justify-content: center;
        }

        .level {
            font-weight: bold;
            margin: 0px 8px;           
        }
        
    }

    .user-status {
        margin: 4px 0px;
        padding: 4px 16px;
        width: fit-content;
        background: #ffffff60;
        border-radius: 24px;
        font-size: 14px;
    }

    //status colors
    .registered {
        background: #73ff5060;
    }
    .active {
        background: #ffdf008c;
    }

    button {
        color: white;
        background-color: #950f0f;
        border: solid 1px #ffffff22;
        padding: 8px;
        border-radius: 4px;
        cursor: pointer;
        margin-bottom: 8px;
        min-width: 68px;
    }

}
