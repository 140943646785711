.authWrapper {
  display: flex;
  justify-content: center;
}

.loginContainer {
  margin: 16px;
  border-radius: 4px;
  padding: 16px;
  background-color: rgba(255, 255, 255, 0.1);
}

.loginContainer h1 {
  margin: 16px 0;
  font-size: large;
}

.formLine {
  margin: 16px;
  width: match-parent;
  display: flex;
  justify-content: space-between;
  vertical-align: bottom;
  line-height: 24px;
}

.formLabel {
  font-weight: bold;
  margin-right: 32px;
  vertical-align: bottom;
}

input {
  border-radius: 4px;
  padding: 4px;
  background-color: rgba(255, 255, 255, 0.5);
}