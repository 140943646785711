
.gamesPage {
    
    .games-header {
        
        display: flex;
        padding: 0px 16px;
        background-color: #ffffff10;

        .tab {
            padding: 16px;
        }

        .tab:hover {
            background-color: #ffffff10;      
        }

        .selected {
            background-color: #ffffff10;
            border-bottom: solid 2px red;    
        }
    }

    .toolbar {
        display: flex;
        justify-content: end;
        margin-top: -48px;
    }

    section {
        padding: 16px;
    }

    .dataGrid {
        font-family: inherit;
        font-size: inherit;
        color: inherit;
        border: solid 1px grey;

        button {
            color: white;
        }

        .css-f3jnds-MuiDataGrid-columnHeaders {
            border: none;
        }

        .MuiDataGrid-cell {
            border-bottom: solid 1px grey;
            background: #ffffff10;
            font-size: 14px;
        }

        .css-i4bv87-MuiSvgIcon-root {
            fill: white;
        }

        .MuiDataGrid-cell.MuiDataGrid-cell--editing:focus-within {
            background: #ffffff10;
            color: white;

            input {
                color: white;  
            }
        }

        .css-17jjc08-MuiDataGrid-footerContainer{
            border: none;

            p {
                color: white;
            }
        }


        .MuiDataGrid-columnSeparator{
            visibility: hidden;
        }
    }

    .overlay {
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: #000000aa;

        .wrapper {
            display: flex;
            justify-content: center;
            padding-top: 80px;
        }

        .form {
            color: white;
            background: #333538;
            padding: 16px;
            width: 75%;
            border-radius: 24px;
            border: solid 4px #950f0f;

            div {
                margin: 16px;
            }

            input {
                margin: 0px 4px;
            }
        }
    }

    .hidden {
        display: none;
    }
    
}