    /* width */
    ::-webkit-scrollbar {
        width: 16px;
    }
    
    /* Track */
    ::-webkit-scrollbar-track {
        box-shadow: inset 0 0 4px grey; 
        border-radius: 8px;
    }
    
    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: rgb(85, 85, 85); 
        border-radius: 8px;
    }
    
    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: rgb(110, 110, 110); ; 
    }