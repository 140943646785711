.users-container {
    display: flex;
    flex-wrap: wrap;

    div {
        margin-bottom: 32px;
    }
}

img {
    margin: 8px;
}
