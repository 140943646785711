.root {
  padding: 20px;
}

h3 {
  font-size: 24px;
  margin-bottom: 16px;
}

.content-section {
  margin-bottom: 20px;
  padding: 0 12px;
}

h4 {
  font-size: 18px;
  margin-bottom: 8px;
}

.content-inner-section {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.content-description {
  font-size: 16px;
  margin-bottom: 12px;
}

.content-image {
  max-width: 80%;
  height: auto;
  margin-bottom: 12px;
}
