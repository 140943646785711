*,
*:before,
*:after,
ul,
li,
a,
button,
input,
h1,
h2,
h3,
h4,
h5,
h6,
p,
img,
image,
svg,
path,
g,
canvas {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	background-color: transparent;
	border: none;
	text-decoration: none;
  font-family: Poppins, Helvetica, sans-serif;
	user-select: none;
	list-style: none;
	position: relative;
}

body {
  background-color: #282c34;
  text-align: center;
  color: white;
}

header {
  height: 64px;
  border-bottom: solid 2px red;
  display: flex;
  justify-content: space-between;
}

header .appLogo {
  height: 64px;
  width: 64px;
  background-image: url('../public/zukanIconRed.png');
  background-size: cover;;
}

header nav a {
  line-height: 64px;
  font-size: 16px;
  padding: 0 16px;
  color: white;
  font-weight: bold;
}

button.primaryBtn {
  color: white;
  background-color: #950f0f;
  border: solid 1px #ffffff22;
  padding: 8px;
  border-radius: 4px;
  cursor: pointer;
  margin-bottom: 8px;
  min-width: 68px;
  margin: 16px;
  font-weight: bold;
  text-transform: uppercase;
}

button.secondaryBtn {
  margin: 16px;
  padding: 8px 16px;
  border-radius: 4px;
  border: solid 2px white;
  color: white;
  font-weight: bold;
  text-transform: uppercase;
}

h3{
  margin: 8px 8px 24px 8px;
}



